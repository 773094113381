import React from 'react'
import Layout from '../components/Layout'
import{ Container } from 'react-bootstrap';
import '../styles/vision.css'
import { useEffect } from 'react'
import AOS from 'aos';
import Head from '../components/Head';


function Vision() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
        AOS.refresh();
    
      }, []);

    return (
        <div className='overflow-hidden'>
            <Head title="Vision and Mission"/>
            <Layout>
                <Container className="vision px-5">

                    
                    <section className='visionPart ' >
                        <header className="sectionHeader halfBorder mb-5   " as="h1">Vision</header>
                        <div className='row'>
                            <div className='vissionPicContainer  col-lg-5 mt-3 mb-5  ps-0' data-aos="fade-up-right">
                                <div className="vissionPic"><h1 title="vision ksss college" className="d-none">vision ksss college</h1></div>
                            </div>
                            <div className='col-lg-7 ps-lg-5 ps-0 ' data-aos="fade-up-left">
                                <p className='sectionContent mb-5'>
                                    We aim to provide quality education with highest academic
                                    standards .Our visionive is to create an environment that will
                                    ensure that our student will develop well rounded personalities
                                    and grow into successful human beings and not just remain
                                    successful students.
                                </p>
                                <ul>
                                    <li>To create an integrated system that meets the higher educational needs of the region and community at large.</li>
                                    <li>To work relentlessly towards community/National development through knowledge and technological skills.</li>
                                    <li>To be efficient, effective community acceptable and excel in education and service.</li>
                                    <li>To impart knowledge and interact with organization of similar interest to meet global competency.</li>
                                    <li>To induce paradigm shifts in community that education is prerequisite for human development and to inculcate the value system.</li>
                                    <li>To reach the unreached and serve the under-served with education contributions to the national development.</li>
                                    <li>To be an active component of national educational system so as to develop the institution as the center of excellence.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="vissionBackgroundContainer">
                            <div className="vissionBackground"></div>
                            
                        </div>
                    </section>


                    <section className='visionPart' >
                        <header className="sectionHeader halfBorder  my-5 " as="h1">Mission</header>
                        <div className='visionFlex row d-flex '>
                            <div className='col-lg-7 pe-lg-5 pe-0 ' data-aos="fade-up-right">
                                <p className='sectionContent  mb-5' >
                                    To maintain the higher standard of discipline and imparting right
                                    value. To install the spirit of dedication to the young minds to
                                    be an asset to the nation. To mould the student/young minds with
                                    best education and higher disciplinary Standards to meet the
                                    global competency. To be the most efficient acceptable and excel
                                    in the field of education
                                </p>

                                <ul>
                                    <li>Learner centered education of excellence.</li>
                                    <li>Develop adaptability to technological skills, to meet the challenges of changing global village effectively.</li>
                                    <li>Induce knowledge that faster self-learning, Continuous improvement and innovation in higher education.</li>
                                    <li>Efficiency, quality, continuous improvement and innovative in all the process of our system.</li>
                                    <li>Unique product differentiation and stakeholder’s satisfaction.</li>
                                    <li>Consultancy referral center.</li>
                                    <li>Unique organizational value based culture.</li>
                                    <li>Open organizational climate.</li>
                                    <li>Inter organizational linkage with institutions of similar interest.</li>
                                    <li>Strategic future oriented planning.</li>
                                    <li>Community oriented skills and research.</li>
                                    {/* <li>Strong community relationships.</li>
                                    <li>Professionalism in education, management and service.</li>
                                    <li>Meet regional and national educational needs.</li> */}
                                </ul>
                            </div>
                            <div className='missionPicConatainer col-lg-5  d-flex align-items-center mb-5  mb-lg-0' data-aos="fade-up-left">
                                <div className="missionPic"><h1 title="mission ksss college" className="d-none">mission ksss college</h1></div>
                            </div>
                        </div>
                    </section>
                        

                        
                </Container>

            </Layout>
            
        </div>
    )
}

export default Vision
